@charset "utf-8";

// Import a Google Font
// @import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$purple: #8A4D76;
$brown: #757763;
$dominosblue: #006491;
$dominosred: #e31837;
$whitebg: #FFFBFB;
// $beige-light: #D0D1CD;
// $beige-lighter: #EFF0EB;

// Update Bulma's global variables
$family-sans-serif: Arial,sans-serif;
// $grey-light: $beige-light;
$primary: $dominosblue;
$danger: $dominosred;
$widescreen-enabled: true;
$fullhd-enabled: false;
$text: #282c34;
$title-color: #282c34;
$tag-color: #282c34;

// Update some of Bulma's component variables
$body-background-color: $whitebg;
$footer-background-color: $whitebg;
$control-border-width: 2px;
$input-border-color: transparent;
$input-shadow: none;
$panel-radius: 0px;

// // Import only what you need from Bulma
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/elements/_all.sass";
@import "../node_modules/bulma/sass/grid/_all.sass";
@import "../node_modules/bulma/sass/form/_all.sass";
@import "../node_modules/bulma/sass/components/_all.sass";
@import "../node_modules/bulma/sass/layout/_all.sass";
$hero-body-padding: 2rem 1rem;

body {
  margin: 0;
  overflow: hidden; }

.table-container {
    min-height: 300px;
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden; }

.m-t-md {
    margin-top: 1rem; }

hr {
  margin-bottom: 0rem; }
